/* Responsive */
@media screen and (max-width: 1920px) {
    .footer .footer-image {
        top: 26%;
        right: 17%;
    }


    /* .home .banner .box .right {
        right: 24%;
    } */


    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -10%;
    }
    .products .all-products .single-product .left {
        padding-top: 3.5%;
    }
}

@media screen and (max-width: 1900px) {
}

@media screen and (max-width: 1850px) {
}

@media screen and (max-width: 1800px) {
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 15px;
    }

    /* .home .banner .box .right {
        right: 22%;
    } */
}

@media screen and (max-width: 1750px) {
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 14px;
    }

    .footer .footer-image {
        right: 16.5%;
    }


    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -11%;
    }
}

@media screen and (max-width: 1680px) {
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 13px;
    }
    [lang=ta] .navbar .nav-item .nav-link {
        font-size: 14px;
    }

    .footer .footer-image {
        right: 16%;
    }
}

@media screen and (max-width: 1600px) {
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 12px;
    }

    .footer .footer-image {
        top: 25%;
        right: 15.5%;
    }


    /* .home .banner .box .right {
        right: 19%;
    } */
    .home .home-products .home-single-product.first .name {
        bottom: -12%;
        right: 16%;
    }



    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -12%;
    }
}

@media screen and (max-width: 1536px) {
    [lang=ta] .navbar .nav-item .nav-link {
        font-size: 13px;
    }

    /* .home .banner .box .right {
        right: 18%;
    } */
}

@media screen and (max-width: 1440px) {
    [lang=ta] .navbar .nav-item .nav-link {
        font-size: 12px;
    }
    [lang=ta] .navbar .nav-item {
        padding: 0 5px;
    }
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 11px;
    }
    [lang=ml] .navbar .nav-item {
        padding: 0 5px;
    }
    [lang=kn] .navbar .nav-item .nav-link {
        font-size: 14px;
    }


    /* .home .banner .box .right {
        right: 13%;
    } */


    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -13%;
    }
}

@media screen and (max-width: 1366px) {
    [lang=ta] .navbar .nav-item .nav-link {
        font-size: 11.5px;
    }
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 10.5px;
    }
    [lang=kn] .navbar .nav-item .nav-link {
        font-size: 13px;
    }


    /* .home .banner .box .right {
        right: 10%;
    } */
    .home .home-awards .swiper-button-next {
        right: 45%;
    }
    .home .home-awards .swiper-button-prev {
        left: 45%;
    }


    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -15%;
    }
}

@media screen and (max-width: 1360px) {
    .home .banner .box img {
        height: 360px;
    }
}

@media screen and (max-width: 1280px) {
    [lang=ta] .navbar .nav-item .nav-link {
        padding: 10px 5px;
    }
    [lang=ml] .navbar .nav-item .nav-link {
        padding: 10px 5px;
    }
    [lang=kn] .navbar .nav-item .nav-link {
        font-size: 13px;
    }
    [lang=kn] .navbar .nav-item {
        padding: 0 5px;
    }


    .low-wrapper {
        max-width: 70%;
    }

    .footer .footer-banner {
        height: 570px;
    }
    .footer .min-wrapper {
        margin-top: -315px;
    }
    .footer .footer-image {
        top: 26%;
        right: 14.5%;
    }


    .home .welcome {
        padding-top: 70px;
        padding-bottom: 35px;
    }
    .home .banner .background {
        height: 300px;
    }
    /* .home .banner .title {
        font-size: 60px;
        top: 50px;
    } */
    /* .home .banner .box .middle {
        height: 300px;
    } */
    /* .home .banner .box .left {
        bottom: 21px;
        left: 222px;
        height: 150px;
    } */
    /* .home .banner .box .right {
        right: 26%;
        height: 110px;
        bottom: 0%;
    } */
    .home .banner .box {
        margin-top: -18%;
    }
    .home .banner .box img {
        height: 275px;
    }
    .home .welcome .title {
        font-size: 35px;
        padding-bottom: 10px;
    }
    .home .welcome .description {
        font-size: 20px;
        padding-bottom: 5px;
    }
    .home .welcome .paragraph {
        font-size: 17px;
        color: rgb(0, 0, 0, 0.35);
        font-weight: 600;
    }
    .home .vision .title {
        font-size: 30px;
    }
    .home .vision .description {
        font-size: 17px;
    }
    .home .vision .back-lines {
        width: 350px;
        height: 290px;
    }
    .home .vision .vision-image {
        width: 355px;
        height: 245px;
        top: 8%;
    }
    .home .vision {
        padding-bottom: 50px;
    }
    .home .mission .title {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .home .mission .single {
        font-size: 16px;
        height: 150px;
    }
    .home .mission {
        padding-bottom: 55px;
    }
    .home .home-about-us .title {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .home .home-about-us .description {
        font-size: 17px;
        padding-bottom: 30px;
    }
    .home .home-about-us .click-here {
        font-size: 14px;
        padding: 10px 25px;
    }
    .home .home-about-us .leaf {
        height: 350px;
    }
    .home .home-about-us .gray-box {
        height: 300px;
    }
    .home .home-about-us .about-image {
        height: 300px;
    }
    .home .home-about-us .w-icon-top, .home .home-about-us .w-icon-bottom {
        height: 25px;
    }
    .home .home-about-us {
        padding-bottom: 60px;
    }
    .home .home-products .home-single-product .content .content-title {
        font-size: 18px;
        padding-bottom: 3px;
    }
    .home .home-products .title {
        font-size: 30px;
    }
    .home .home-products {
        padding-bottom: 50px;
    }
    .home .home-products .home-single-product .content .attributes {
        font-size: 15px;
    }
    .home .home-products .home-single-product .name {
        width: 100px;
        height: 100px;
        font-size: 13px;
    }
    .home .home-products .home-single-product.first .name {
        right: 10%;
    }
    .home .home-products .home-single-product.second .name {
        left: 10%;
    }
    .home .home-products .home-single-product {
        margin-bottom: 60px;
    }
    .home .home-products .more-products {
        font-size: 14px;
    }
    .home .home-awards {
        padding-bottom: 15px;
    }
    .home .home-awards .title {
        font-size: 30px;
    }
    .home .home-awards .swiper-wrapper {
        padding-bottom: 100px;
    }
    .home .home-testimonials .title {
        font-size: 30px;
    }
    .home .home-testimonials .single-testimonial .single .quote .description {
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .home .home-testimonials .single-testimonial .single .quote .name {
        font-size: 14px;
    }
    .home .home-testimonials .single-testimonial .single {
        height: 300px;
        padding: 15px 30px;
    }
    .home .home-testimonials .single-testimonial .single .quote .quote-icon.bottom {
        bottom: 10px;
    }
    .home .home-testimonials .swiper-pagination-clickable .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
    }
    .home .home-testimonials .swiper-wrapper {
        padding-bottom: 45px;
    }
    .home .home-testimonials {
        padding-bottom: 50px;
    }
    .home .articles .single-article .right .title {
        font-size: 25px;
    }
    .home .articles .single-article .right .description {
        font-size: 15px;
        -webkit-line-clamp: 11;
        line-clamp: 11;
    }
    .home .articles .single-article .left .article-image {
        height: 325px;
    }
    .home .articles .swiper-button-next {
        height: 35px;
        width: 35px;
    }
    .home .articles .swiper-button-prev {
        height: 35px;
        width: 35px;
    }
    .home .articles .swiper-button-next:after, .home .articles .swiper-button-prev:after {
        font-size: 12px;
    }

}

@media screen and (max-width: 1024px) {
    [lang=ta] .navbar .nav-item .nav-link {
        font-size: 12px;
        text-align: center;
        padding: 3px 5px;
        width: -moz-fit-content;
        max-width: min-content;
    }
    [lang=ml] .navbar .nav-item .nav-link {
        font-size: 8.5px;
        text-align: center;
        padding: 5px 6px;
        width: -moz-fit-content;
        max-width: min-content;
    }

    [lang=kn] .navbar .nav-item .nav-link {
        font-size: 11px;
    }
    [lang=kn] .navbar .nav-item {
        padding: 0 2px;
    }

    .low-wrapper {
        max-width: 80%;
    }
    .min-wrapper {
        max-width: 100%;
    }

    .navbar .nav-item .nav-link {
        font-size: 13px;
        padding: 5px;
    }
    .navbar .nav-item {
        padding: 0 5px;
    }
    .goog-te-combo {
        width: 115px;
        font-size: 11px;
    }

    .footer .column1 {
        width: 16%;
    }
    .footer .column2 {
        width: 18%;
    }
    .footer .column3 {
        width: 15%;
    }
    .footer .footer-image {
        top: 26%;
        right: 12.5%;
    }


    /* .home .banner .box .left {
        left: 140px;
    } */
    /* .home .banner .box .right {
        right: 20%;
    } */
    .home .banner .box {
        margin-top: -24%;
    }
    .home .vision .vision-image {
        width: 350px;
    }
    .home .home-awards .swiper-button-prev {
        left: 43%;
    }
    .home .home-awards .swiper-button-next {
        right: 43%;
    }
    .home .articles .single-article .left .article-image {
        height: 300px;
    }


    .about-us {
        margin-top: 75px;
    }
    .about-us .main-about .title {
        font-size: 25px;
    }
    .about-us .description {
        font-size: 15px;
    }
    .about-us .main-about .about-image {
        height: 200px;
        width: 200px;
    }
    .about-us .main-about {
        padding-bottom: 35px;
    }
    .about-us .title {
        font-size: 25px;
    }
    .about-us .brands {
        padding-bottom: 50px;
    }
    .about-us .supply .map {
        height: 350px;
        margin-bottom: 5px;
    }
    .about-us .supply {
        padding-bottom: 40px;
    }
    .about-us .company .video-div {
        padding-top: 0px;
    }
    .about-us .company .video-image {
        width: 550px;
        height: 300px;
    }
    .about-us .company iframe {
        width: 550px;
        height: 300px;
    }
    .about-us .company .blur {
        width: 550px;
        height: 300px;
    }
    .about-us .company {
        padding-bottom: 45px;
    }
    .about-us .management .founder-image {
        height: 300px;
        margin-bottom: 5px;
    }
    .about-us .management .name {
        font-size: 14px;
    }
    .about-us .management .designation {
        font-size: 12px;
    }
    .about-us .management .top-row {
        margin-bottom: 35px;
    }
    /* .about-us .management .manager-image {
        height: 300px;
        margin-bottom: 5px;
    } */
    .about-us .management .member-image {
        height: 300px;
        margin-bottom: 5px;
    }
    .about-us .management .top-column .description {
        font-size: 14px;
    }
    .about-us .management .bottom-row .top-column {
        /* margin-bottom: 40px; */
        margin-bottom: 10px;
    }
    .about-us .management .award-image {
        height: 350px;
        margin-bottom: 5px;
    }
    /* .about-us .management {
        padding-bottom: 40px;
    } */
    .about-us .members .description {
        margin-bottom: 20px;
    }
    .about-us .members .management-image {
        height: 300px;
        margin-bottom: 5px;
    }
    .about-us .members .name {
        font-size: 14px;
    }
    .about-us .members .designation {
        font-size: 12px;
    }
    .about-us .members .top-row {
        margin-bottom: 0px;
    }



    .products {
        padding-top: 25px;
    }
    .products .title {
        font-size: 30px;
        padding-bottom: 10px;
    }
    .products .description {
        font-size: 14px;
        width: 80%;
    }
    .products .all-products .single-product {
        margin-top: 30px;
    }
    .products .all-products .single-product .right .product-title {
        font-size: 20px;
        padding-bottom: 3px;
    }
    .products .all-products .single-product .right .product-description {
        font-size: 14px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column img {
        height: 125px;
        border: 3px solid var(--white);
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .image-name-div .image-name {
        font-size: 13px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .image-description {
        font-size: 13px;
        padding-top: 15px;
    }
    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -14%;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .bar {
        height: 65px;
    }



    .members {
        padding-top: 25px;
    }
    .members .title {
        font-size: 30px;
    }
    .members .all-members {
        margin-top: 30px;
    }
    .members .description {
        font-size: 14px;
    }
    .members .member-image {
        height: 300px;
        margin-bottom: 5px;
    }
    .members .all-members .name {
        font-size: 14px;
    }
    .members .all-members .designation {
        font-size: 12px;
    }



    .awards {
        padding-top: 25px;
    }
    .awards .title {
        font-size: 30px;
    }
    .awards .all-awards .single-award {
        margin-left: 0;
        margin-right: 0;
        padding: 20px 10px;
    }
    .awards .all-awards .single-award .award-title {
        font-size: 18px;
    }
    .awards .all-awards .single-award .award-description {
        font-size: 15px;
    }
    .awards .all-awards .single-award .image-div .award-image {
        height: 300px;
    }



    .testimonials {
        padding-top: 25px;
    }
    .testimonials .title {
        font-size: 30px;
    }
    .testimonials .reviews {
        margin-top: 20px;
    }
    .testimonials .single-review {
        height: 400px;
        padding: 0 25px;
    }
    .testimonials .reviews .description {
        font-size: 15px;
        padding-bottom: 20px;
    }
    .testimonials .reviews .name {
        font-size: 14px;
        padding-bottom: 0px;
    }
    .testimonials .reviews .designation {
        font-size: 11px;
    }



    .blogs .articles .title {
        font-size: 30px;
        padding-bottom: 20px;
    }
    .blogs .articles .nav-pills .nav-item .nav-link {
        font-size: 15px;
        width: 80px;
        height: 35px;
    }
    .blogs .articles .top .blog-main-title {
        font-size: 18px;
        padding-bottom: 5px;
    }
    .blogs .articles .top .blog-main-description {
        font-size: 15px;
        -webkit-line-clamp: 8;
        line-clamp: 8;
    }
    .blogs .articles .top .blog-main-image {
        height: 280px;
    }
    .blogs .articles .top .author-image {
        height: 50px;
        width: 50px;
        margin-right: 10px;
    }
    .blogs .articles .top .author .details .name {
        font-size: 14px;
    }
    .blogs .articles .top .author .details .date {
        font-size: 10px;
    }
    .blogs .articles .top .author {
        padding-top: 15px;
    }
    .blogs .articles .bottom .single-article {
        margin-top: 25px;
    }
    .blogs .articles .bottom .blog-sub-title {
        font-size: 15px;
        padding-bottom: 3px;
    }
    .blogs .articles .bottom .blog-sub-description {
        font-size: 14px;
    }
    .blogs .articles .bottom .author-image {
        height: 35px;
        width: 35px;
        margin-right: 7px;
    }
    .blogs .articles .bottom .author .details .name {
        font-size: 13px;
    }
    .blogs .articles .bottom .author {
        padding-top: 10px;
    }
    .blogs .search .form-control {
        height: 50px;
        font-size: 16px;
    }
    .blogs .search .search-icon {
        font-size: 18px;
        height: 40px;
        width: 40px;
    }
    .blogs .search .form-control::placeholder {
        letter-spacing: 2px;
        font-size: 14px;
    }
    .single-blog .title {
        font-size: 30px;
        padding-bottom: 15px;
    }
    .single-blog .image {
        height: 300px;
        margin-bottom: 10px;
    }
    .single-blog .description {
        font-size: 15px;
        line-height: 25px;
    }



    .contact-us .connected .title {
        font-size: 30px;
        padding-bottom: 15px;
    }
    .contact-us .connected .form {
        padding: 30px;
    }
    .contact-us .connected .form .form-title {
        font-size: 20px;
        padding-bottom: 25px;
    }
    .contact-us .connected .form .fields {
        padding: 0px 50px;
    }
    .contact-us .contact .information .title {
        font-size: 20px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 992px) {
    [lang=ta] .navbar .nav-item .nav-link, [lang=ml] .navbar .nav-item .nav-link, [lang=ml] .navbar .nav-item .nav-link {
        font-size: 16px;
        padding: 5px;
        text-align: initial;
        max-width: initial;
    }
    [lang=kn] .navbar .nav-item, [lang=ml] .navbar .nav-item, [lang=ta] .navbar .nav-item {
        padding: 5px;
    }
    
    .navbar {
        height: auto;
    }
    .navbar-nav {
        text-align: center;
    }
    .navbar .container-fluid {
        padding: 20px 30px;
    }
    .navbar .navbar-brand .logo {
        height: 100px;
        width: 90px;
    }
    .navbar .nav-item .nav-link {
        display: inline-block;
        font-size: 16px;
    }
    .navbar-toggler {
        border: 3px solid transparent;
    }
    .navbar-toggler:focus {
        box-shadow: none;
        border: 3px solid var(--green);
    }
    .goog-te-combo {
        width: 125px;
        font-size: 12px;
    }
    #google_translate_element {
        justify-content: center;
    }
    .navbar-nav .dropdown-menu {
        text-align: center;
        padding: 0;
        margin: auto;
    }
    .navbar-nav .dropdown-menu.show {
        display: table;
    }

    .footer .footer-banner {
        height: 450px;
    }
    .footer .min-wrapper {
        margin-top: -250px;
    }
    .footer .min-wrapper .top {
        margin-bottom: 10px;
    }
    .footer .social {
        padding-top: 15px;
    }
    .footer .to-top {
        right: 2%;
        font-size: 15px;
        height: 30px;
        width: 30px;
    }
    .footer .footer-image {
        top: 22%;
        right: 12%;
    }


    /* .home .banner .box .left {
        left: 120px;
    } */
    .home .welcome {
        padding-top: 60px;
    }


    .about-us {
        margin-top: 30px;
    }



    .testimonials .single-review .shape {
        height: 110px;
    }



    .blogs .search {
        padding-top: 25px;
    }
    .blogs .articles {
        padding-top: 25px;
    }
    .single-blog {
        padding-top: 25px;
    }



    .contact-us {
        margin-top: 25px;
    }
    .contact-us .contact .information {
        padding: 20px;
    }
}

@media screen and (max-width: 844px) {
    .low-wrapper {
        max-width: 85%;
    }

    .footer .min-wrapper {
        margin-top: -260px;
    }
    .footer .social {
        padding-top: 10px;
    }
    .footer .min-wrapper .top {
        margin-bottom: 15px;
    }
    .footer .footer-image {
        top: 21%;
        right: 10%;
    }


    /* .home .banner .box .left {
        left: 110px;
        height: 100px;
    } */
    /* .home .banner .box .right {
        right: 15%;
    } */
    .home .vision .vision-image {
        width: 295px;
    }
    .home .home-about-us .gray-box {
        width: 40%;
    }
    .home .home-about-us .about-image {
        width: 95%;
    }


    .about-us .brands .brand-image {
        height: 100px;
    }
    .about-us .title {
        padding-bottom: 20px;
    }
    .about-us .management .founder-image {
        height: 250px;
    }
    /* .about-us .management .manager-image {
        height: 250px;
    } */
    .about-us .management .member-image {
        height: 250px;
    }
    .about-us .management .top-column .description {
        margin-top: 0%;
    }
    .about-us .members .management-image {
        height: 250px;
    }
    .about-us .members .bottom-row .column {
        margin-top: 25px;
    }


    .members .member-image {
        height: 250px;
    }



    .products .all-products .single-product .top-row {
        padding: 25px 15px 45px 15px;
    }
    .products .all-products .single-product .left {
        padding-top: 4%;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column img {
        height: 100px;
    }
    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -17%;
    }


    .awards .all-awards .single-award .image-div {
        height: 300px;
    }
    .awards .all-awards .single-award .image-div .award-image {
        height: 270px;
    }


    .testimonials .single-review {
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        max-width: 95%;
    }
    .lowest-wrapper {
        max-width: 95%;
    }


    .footer {
        padding-top: 0px;
    }
    .footer .footer-banner {
        height: 630px;
    }
    .footer .min-wrapper {
        margin-top: -365px;
    }
    .footer .column1 {
        width: 20%;
    }
    .footer .column2 {
        width: 30%;
    }
    .footer .column3 {
        width: 25%;
    }
    .footer .column4 {
        width: 25%;
    }
    .footer .column5 {
        width: 100%;
        padding-top: 0px;
    }
    .footer .footer-image {
        top: 22%;
        right: 9%;
    }


    .home .banner .background {
        height: 250px;
    }
    /* .home .banner .box .left {
        left: 105px;
        bottom: -7px;
    } */
    /* .home .banner .box .middle {
        height: 225px;
    } */
    /* .home .banner .box .right {
        right: 7%;
    } */
    /* .home .banner .box .right {
        right: 12%;
        bottom: -10%;
    } */
    .home .banner .box img {
        height: 200px;
    }
    .home .welcome {
        padding-top: 35px;
        padding-bottom: 25px;
    }
    .home .welcome .title {
        font-size: 25px;
        padding-bottom: 5px;
    }
    .home .welcome .title .famco {
        letter-spacing: 3px;
        padding: 0 8px 0 10px;
    }
    .home .welcome .description {
        font-size: 17px;
        padding-bottom: 0px;
    }
    .home .welcome .paragraph {
        font-size: 15px;
    }
    .home .vision .title {
        font-size: 25px;
        padding-bottom: 5px;
    }
    .home .vision .description {
        font-size: 15px;
    }
    .home .vision .left {
        padding-top: 30px;
    }
    .home .vision .vision-image {
        width: 275px;
    }
    .home .mission .title {
        font-size: 25px;
        padding-bottom: 5px;
    }
    .home .mission .single {
        font-size: 14px;
        height: 135px;
    }
    .home .home-about-us .title {
        font-size: 25px;
        padding-bottom: 5px;
    }
    .home .home-about-us .leaf {
        top: -5%;
        left: 10%;
        height: 300px;
    }
    .home .home-about-us .description {
        font-size: 15px;
        padding-bottom: 20px;
    }
    .home .home-about-us .about-image {
        height: 250px;
    }
    .home .home-about-us .gray-box {
        height: 250px;
    }
    .home .home-about-us .w-icon-top, .home .home-about-us .w-icon-bottom {
        height: 16px;
    }
    .home .home-about-us .w-icon-bottom {
        top: -8%;
    }
    .home .home-about-us .w-icon-top {
        top: -16%;
    }
    .home .home-about-us {
        padding-bottom: 45px;
    }
    .home .home-products .title {
        font-size: 25px;
        padding-bottom: 20px;
    }
    .home .home-products .home-single-product .content {
        padding: 50px;
    }
    .home .home-products .home-single-product .image {
        height: 265px;
    }
    .home .home-products .home-single-product.first .name {
        right: -5%;
    }
    .home .home-products .home-single-product.second .name {
        left: -5%;
    }
    .home .home-products {
        padding-bottom: 35px;
    }
    .home .home-awards {
        padding-bottom: 20px;
    }
    .home .home-awards .title {
        font-size: 25px;
        padding-bottom: 25px;
    }
    .home .home-awards .single-award .single {
        padding: 20px;
    }
    .home .home-awards .single-award .single .award {
        height: 160px;
        width: 160px;
        margin-bottom: 15px;
    }
    .home .home-awards .swiper-button-prev {
        left: 41%;
    }
    .home .home-awards .swiper-button-next {
        right: 41%;
    }
    .home .home-testimonials {
        padding-bottom: 35px;
    }
    .home .home-testimonials .title {
        font-size: 25px;
        padding-bottom: 25px;
    }
    .home .articles .single-article .left .article-image {
        height: 250px;
    }
    .home .articles .single-article .right .title {
        font-size: 22px;
    }
    .home .articles .single-article .right .description {
        font-size: 15px;
        -webkit-line-clamp: 9;
        line-clamp: 9;
    }
    .home .articles .swiper-button-next {
        right: 49%;
    }



    .about-us .description {
        font-size: 14px;
    }
    .about-us .brands .brand-image {
        margin: 0 15px;
    }
    .about-us .management .award-image {
        height: 300px;
    }


    .products .title {
        font-size: 25px;
    }
    .products .description {
        width: 90%;
    }
    .products .all-products .single-product .top-row {
        padding: 25px 0px 45px 
    }
    .products .all-products .single-product .left {
        padding-top: 0%;
        width: 100%;
        margin-bottom: 15px;
    }
    .products .all-products .single-product .left .white-box .main-image .featured-image {
        width: 100%;
        height: 325px;
    }
    .products .all-products .single-product .left .white-box .other-images .other-image {
        width: 130px;
        height: 100px;
    }
    .products .all-products .single-product .right {
        width: 100%;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column {
        width: 33%;
        margin-bottom: 20px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column img {
        height: 150px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .bar {
        display: none;
    }
    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -52%;
        height: 400px;
    }




    .testimonials .title {
        font-size: 25px;
    }



    .awards .title {
        font-size: 25px;
    }
    .awards .all-awards .single-award {
        margin-top: 30px;
    }



    .blogs .articles .title {
        font-size: 25px;
        padding-bottom: 15px;
    }
    .blogs .articles .nav-pills {
        justify-content: center;
        padding-bottom: 25px;
    }
    .blogs .articles .top .blog-main-image {
        height: 230px;
    }
    .blogs .articles .top .blog-main-description {
        font-size: 14px;
        -webkit-line-clamp: 6;
        line-clamp: 6;
    }
    .blogs .articles .bottom .single-article {
        width: 50%;
    }
    .single-blog .title {
        font-size: 25px;
        padding-bottom: 10px;
    }
    .single-blog .image {
        height: 270px;
    }
    .single-blog .description {
        font-size: 14px;
        line-height: 22px;
    }



    .contact-us .connected .title {
        font-size: 25px;
    }
    .contact-us .connected .form .form-title {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .contact-us .connected .form .fields {
        padding: 0px 20px;
    }
    .contact-us .connected .form {
        padding: 20px;
    }
    .contact-us .connected .form .send-btn {
        width: 135px;
        font-size: 16px;
    }
    .contact-us .contact .information .title {
        font-size: 18px;
        padding-bottom: 20px;
    }
    .contact-us .contact .information .location-title {
        font-size: 16px;
        padding: 10px 25px;
        top: -10px;
    }
    .contact-us .contact .information .social {
        padding: 14px 10px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 650px) {
    .home .welcome {
        padding-bottom: 20px;
    }
    .home .vision {
        padding-bottom: 30px;
    }
    .home .vision .left {
        padding-top: 5px;
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
    }
    .home .vision .title {
        text-align: center;
    }
    .home .vision .description {
        text-align: center;
    }
    .home .vision .leaf {
        top: 25%;
        left: 40%;
        height: 135px;
    }
    .home .vision .back-lines {
        display: none;
    }
    .home .vision .vision-image {
        width: 100%;
        height: 310px;
        position: relative;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0px;
        top: 0%;
    }
    .home .vision .right {
        padding-left: 0;
        padding-right: 0;
    }

    .home .mission {
        padding-bottom: 20px;
    }
    .home .mission .single {
        width: 45%;
        margin: 10px;
    }
    .home .home-about-us .left {
        padding: 0;
        margin-bottom: 25px;
    }
    .home .home-about-us .title {
        padding-bottom: 10px;
    }
    .home .home-about-us .top {
        display: none;
    }
    .home .home-about-us .w-icon-top, .home .home-about-us .w-icon-bottom {
        display: none;
    }
    .home .home-about-us .title.mobile {
        display: block;
        text-align: center;
    }
    .home .home-about-us .title.desktop {
        display: none;
    }
    .home .home-about-us .about-image {
        width: 100%;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
    .home .home-about-us .leaf {
        top: -5%;
        left: 35%;
        height: 200px;
    }
    .home .home-products .home-single-product.first .name {
        right: 20%;
        bottom: 0;
    }
    .home .home-products .home-single-product .content {
        padding: 25px;
        border-radius: 30px;
    }
    .home .home-products .home-single-product.mobile{
        display: flex;
    }
    .home .home-products .home-single-product.desktop{
        display: none;
    }
    .home .home-products .home-single-product {
        margin-bottom: 40px;
    }
    .home .home-products .home-single-product .name {
        width: 85px;
        height: 85px;
    }
    .home .home-products .home-single-product.first .name {
        right: 22%;
    }
    .home .articles .single-article .left {
        width: 100%;
        padding: 0;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 10px;
    }
    .home .articles .single-article .right {
        width: 100%;
    }
    .home .articles .swiper-button-next {
        right: 0%;
    }
    .home .articles .swiper-button-next, .home .articles .swiper-button-prev {
        top: 27%;
    }
}

@media screen and (max-width: 428px) {
    .low-wrapper {
        max-width: 95%;
    }

    .navbar .nav-item .nav-link {
        font-size: 15px;
    }
    .footer {
        background-color: var(--dark_green);
        margin-top: 35px;
        padding-bottom: 20px;
    }
    .footer .footer-banner {
        display: none;
    }
    .footer .min-wrapper {
        margin-top: 20px;
    }
    .footer .column1 {
        width: 30%;
    }
    .footer .column2 {
        width: 70%;
    }
    .footer .column3 {
        width: 50%;
        padding-top: 0px;
        margin-top: 15px;
    }
    .footer .column4 {
        width: 50%;
        padding-top: 0px;
        margin-top: 15px;
    }
    .footer .footer-logo {
        height: 130px;
        width: 100px;
    }
    .footer .title {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .footer ul li {
        padding-bottom: 0px;
    }
    .footer ul li a {
        font-size: 15px;
    }
    .footer .social {
        padding-top: 15px;
    }
    .footer .to-top {
        right: 3%;
        bottom: 13%;
    }
    .footer .min-wrapper .top {
        margin-bottom: 20px;
    }
    .footer .bottom .copyright {
        font-size: 13px;
    }
    .footer .name {
        font-size: 20px;
    }
    .footer ul li a {
        font-size: 14px;
    }


    .home .banner .background {
        height: 180px;
    }
    /* .home .banner .title {
        font-size: 30px;
        top: 25px;
    } */
    /* .home .banner .box .middle {
        height: 145px;
    } */
    /* .home .banner .box .left {
        display: none;
    } */
    /* .home .banner .box .right {
        display: none;
    } */
    .home .banner .box img {
        height: 120px;
    }
    .home .welcome {
        padding-top: 25px;
    }
    .home .welcome .title {
        font-size: 20px;
    }
    .home .welcome .description {
        font-size: 16px;
    }
    .home .welcome .paragraph {
        font-size: 14px;
    }
    .home .vision .title {
        font-size: 20px;
    }
    .home .vision .description {
        font-size: 14px;
    }
    .home .vision .vision-image {
        height: 225px;
    }
    .home .mission .title {
        font-size: 20px;
        padding-bottom: 0px;
    }
    .home .mission .single {
        font-size: 13px;
        height: 100px;
        padding: 0 10px;
    }
    .home .home-about-us .title {
        font-size: 20px;
        padding-bottom: 5px;
    }
    .home .home-about-us .about-image {
        height: 225px;
    }
    .home .home-about-us .right {
        margin-top: 0%;
        padding: 0;
    }
    .home .home-about-us .description {
        font-size: 14px;
    }
    .home .home-about-us .left {
        margin-bottom: 15px;
    }
    .home .home-about-us {
        padding-bottom: 40px;
    }
    .home .home-products .title {
        font-size: 20px;
        padding-bottom: 10px;
    }
    .home .home-products .home-single-product .content .content-title {
        font-size: 16px;
    }
    .home .home-products .home-single-product .content .attributes {
        font-size: 14px;
    }
    .home .home-products .home-single-product .content {
        padding: 20px;
        border-radius: 20px;
    }
    .home .home-products .home-single-product .name {
        color: var(--white);
        right: 0%!important;
        position: relative;
        border-radius: 0;
        width: 250px;
        height: 60px;
    }
    .home .home-products .home-single-product {
        margin-bottom: 30px;
    }
    .home .home-products .home-single-product .name {
        width: 325px;
    }
    .home .home-products .home-single-product .image {
        width: 325px;
    }
    .home .home-awards .swiper-button-prev {
        left: 38%;
    }
    .home .home-awards .swiper-button-next {
        right: 38%;
    }
    .home .home-awards .swiper-wrapper {
        padding-bottom: 80px;
    }
    .home .home-awards .single-award .single .description {
        font-size: 14px;
    }
    .home .home-awards .swiper-button-next:after, .home .home-awards .swiper-button-prev:after {
        font-size: 14px;
    }
    .home .home-awards .swiper-button-next, .home .home-awards .swiper-button-prev {
        height: 35px;
        width: 40px;
    }
    .home .home-awards {
        padding-bottom: 10px;
    }
    .home .home-awards .title {
        font-size: 20px;
        padding-bottom: 15px;
    }
    .home .home-testimonials .single-testimonial .single .quote .description {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .home .home-testimonials .single-testimonial .single .image .testimonial-back {
        height: 90px;
    }
    .home .home-testimonials .single-testimonial .single .testimonial-image {
        width: 65px;
        height: 65px;
        left: 11.5%;
    }
    .home .home-testimonials .single-testimonial .single .quote .name {
        font-size: 13px;
    }
    .home .home-testimonials .single-testimonial .single {
        height: 280px;
        padding: 15px 10px;
    }
    .home .home-testimonials .single-testimonial .single .quote .quote-icon {
        height: 20px;
        width: 22px;
    }
    .home .home-testimonials .title {
        font-size: 20px;
        padding-bottom: 15px;
    }
    .home .articles .single-article .right .title {
        font-size: 20px;
    }
    .home .articles .single-article .right .description {
        font-size: 14px;
        -webkit-line-clamp: 8;
        line-clamp: 8;
        padding: 0 14px;
    }


    .about-us .main-about .title {
        font-size: 20px;
    }
    .about-us .description {
        text-align: center;
    }
    .about-us .main-about .background {
        width: 100%;
        margin-left: initial;
    }
    .about-us .main-about {
        padding-bottom: 30px;
    }
    .about-us .title {
        font-size: 20px;
        padding-bottom: 15px;
    }
    .about-us .brands {
        padding-bottom: 30px;
    }
    .about-us .supply {
        padding-bottom: 30px;
    }
    .about-us .supply .map {
        height: 300px;
        margin-bottom: 0px;
    }
    .about-us .company {
        padding-bottom: 30px;
    }
    .about-us .company .pos-abs {
        display: none;
    }
    .about-us .company iframe {
        width: 100%;
        height: 300px;
    }
    .about-us .management .top-row {
        margin-bottom: 25px;
    }
    .about-us .management .founder-image {
        height: 300px;
    }
    /* .about-us .management .manager-image {
        height: 300px;
    } */
    .about-us .management .member-image {
        height: 300px;
    }
    .about-us .management .bottom-row .top-column {
        /* margin-bottom: 20px; */
        margin-bottom: 0px;
    }
    .about-us .management .award-image {
        height: 230px;
    }
    /* .about-us .management {
        padding-bottom: 30px;
    } */
    .about-us .members .description {
        margin-bottom: 10px;
    }
    .about-us .members .bottom-row .column {
        width: 50%;
        margin-top: 20px;
    }



    .products .title {
        font-size: 20px;
    }
    .products .description {
        width: 100%;
    }
    .products .all-products .single-product .top-row {
        background-image: linear-gradient(to bottom right, #166C56, rgba(15, 68, 5, 0.92));
    }
    .products .all-products .single-product .left .white-box .main-image .featured-image {
        height: 300px;
    }
    .products .all-products .single-product .left .white-box .other-images .other-image {
        width: 100px;
        height: 80px;
    }
    .products .all-products .single-product .right .product-title {
        font-size: 18px;
        padding-bottom: 0px; 
    }
    .products .all-products .single-product .bottom-row {
        margin-top: -7%;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column {
        width: 50%;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column img {
        height: 125px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .image-name-div .image-name {
        font-size: 11px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column .image-description {
        padding-top: 10px;
    }
    .products .all-products .single-product .bottom-row .background-box {
        background-image: linear-gradient(to bottom right, #166C56, rgb(76 140 18 / 90%));
        margin-top: -130%;
        height: 525px;
    }



    .members .title {
        font-size: 20px;
    }
    .members .all-members {
        margin-top: 15px;
    }
    .members .member-image {
        width: 100%;
        margin-bottom: 5px;
    }
    .members .description {
        margin-top: 0%;
        text-align: center;
    }
    .members .member-image {
        height: 300px;
    }



    .awards .title {
        font-size: 20px;
    }
    .awards .all-awards .single-award {
        margin-top: 20px;
    }
    .awards .all-awards .single-award .award-title {
        font-size: 16px;
        padding-bottom: 0;
    }
    .awards .all-awards .single-award .award-description {
        font-size: 14px;
    }



    .testimonials .title {
        font-size: 20px;
    }
    .testimonials .reviews {
        margin-top: 15px;
    }
    .testimonials .single-review {
        height: 330px;
        padding: 0 25px;
        margin-bottom: 50px;
    }
    .testimonials .single-review .quote {
        left: 18px;
        top: 6%;
        height: 25px;
    }
    .testimonials .swiper-pagination-clickable .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
    }
    .testimonials .reviews .bar {
        width: 45px;
        height: 5px;
    }



    .blogs .articles .top {
        padding-bottom: 0px;
    }
    .blogs .articles .top .left {
        width: 100%;
        margin-bottom: 15px;
        padding: 0;
    }
    .blogs .articles .top .blog-main-title {
        font-size: 15px;
        padding-bottom: 3px;
        text-align: left;
    }
    .blogs .articles .top .blog-main-description {
        text-align: left;
        -webkit-line-clamp: 3;
        line-clamp: 3;
    }
    .blogs .articles .top .right {
        width: 100%;
        padding: 0;
    }
    .blogs .articles .top .author {
        padding-top: 10px;
    }
    .blogs .articles .bottom .single-article {
        width: 100%;
        padding: 0;
    }
    .blogs .articles .top .blog-main-image {
        height: 200px;
    }
    .blogs .articles .bottom .blog-sub-image {
        height: 200px;
        margin-bottom: 10px;
    }
    .blogs .articles .top .author-image {
        height: 35px;
        width: 35px;
        margin-right: 7px;
    }
    .blogs .articles .top .author .details .name {
        font-size: 13px;
    }
    .blogs .articles .top .left {
        margin-bottom: 10px;
    }
    .single-blog .title {
        font-size: 20px;
    }
    .single-blog .image {
        height: 240px;
    }
    



    .contact-us .connected .form .form-title {
        font-size: 17px;
    }
    .contact-us .connected .form .fields {
        padding: 0px 0px;
    }
    .contact-us .connected .form {
        padding: 20px 20px 10px 20px;
    }
    .contact-us .connected {
        padding-bottom: 0px;
    }
    .contact-us .contact .information {
        padding: 20px 5px 20px 5px;
        margin-top: 30px;
    }
    .contact-us .contact .information .social {
        margin-top: 20px;
    }
    .contact-us .contact .information iframe {
        margin-bottom: 10px;
        height: 225px;
    }
    .contact-us .contact .information .contact-icon {
        padding-bottom: 5px;
    }
    .contact-us .contact .information .title {
        font-size: 17px;
        padding-bottom: 15px;
    }
    .contact-us .contact .information .address {
        font-size: 15px;
    }
    .contact-us .contact .information .contact-icon a {
        font-size: 15px;
    }
    .contact-us .contact .information .contact-icon a img {
        height: 25px;
    }
}

@media screen and (max-width: 414px) {
    .testimonials .single-review.swiper-slide-active {
        width: 370px!important;
    }
}

@media screen and (max-width: 395px) {
    .home .mission .single {
        font-size: 13px;
        height: 110px;
        padding: 0 5px;
        width: 43%;
        margin: 5px;
    }
    .home .home-products .home-single-product .image {
        height: 230px;
    }
    .home .home-products .home-single-product .image {
        width: 100%;
    }
    .home .home-products .home-single-product .name {
        width: 100%;
    }


    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -143%;
        height: 540px;
    }


    .testimonials .single-review.swiper-slide-active {
        width: 355px!important;
    }
}

@media screen and (max-width: 376px) {
    .footer .column1 {
        width: 35%;
    }
    .footer .column2 {
        width: 65%;
    }



    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -150%;
    }


    .testimonials .single-review.swiper-slide-active {
        width: 335px!important;
    }



    .awards .all-awards .single-award .image-div {
        height: 280px;
    }
    .awards .all-awards .single-award .image-div .award-image {
        height: 250px;
    }



    .blogs .articles .title {
        font-size: 22px;
    }
    .blogs .articles .nav-pills .nav-item .nav-link {
        font-size: 14px;
        width: 70px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 360px) {
    .home .banner .box {
        margin-top: -30%;
    }
    
    .products .all-products .single-product .left .white-box .other-images .other-image {
        margin: 0px 5px;
        padding: 5px;
    }
    .products .all-products .single-product .left .white-box .main-image .featured-image {
        height: 250px;
    }
    .products .all-products .single-product .bottom-row .sub-images .image-column img {
        height: 100px;
    }
    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -135%;
        height: 460px;
    }


    .testimonials .single-review.swiper-slide-active {
        width: 100%!important;
    }
}

@media screen and (max-width: 320px) {
    .home .mission .single {
        width: 95%;
    }
    .home .home-products .home-single-product .content .content-title {
        font-size: 15px;
    }
    .home .home-products .home-single-product .content .attributes {
        font-size: 13px;
    }
    .home .home-products .home-single-product .content {
        padding: 10px;
        border-radius: 10px;
    }
    .home .home-awards .swiper-button-next {
        right: 35%;
    }
    .home .home-awards .swiper-button-prev {
        left: 35%;
    }
    .home .articles .single-article .left .article-image {
        height: 200px;
    }
    .home .articles .swiper-button-next, .home .articles .swiper-button-prev {
        top: 24%;
    }


    .about-us .main-about .title {
        font-size: 18px;
    }
    .about-us .title {
        font-size: 18px;
        padding-bottom: 10px;
    }
    .about-us .main-about {
        padding-bottom: 25px;
    }
    .about-us .brands {
        padding-bottom: 25px;
    }
    .about-us .supply .map {
        height: 260px;
    }
    .about-us .supply {
        padding-bottom: 25px;
    }
    .about-us .company iframe {
        height: 200px;
    }
    .about-us .company {
        padding-bottom: 25px;
    }
    .about-us .management .founder-image {
        height: 230px;
    }
    /* .about-us .management .manager-image {
        height: 250px;
    } */
    .about-us .management .member-image {
        height: 250px;
    }
    /* .about-us .management {
        padding-bottom: 25px;
    } */
    .about-us .members .management-image {
        height: 200px;
    }



    .products .title {
        font-size: 18px;
    }
    .products .all-products .single-product .left .white-box {
        padding: 5px;
    }
    .products .all-products .single-product .left .white-box .other-images .other-image {
        width: 80px;
        height: 65px;
    }
    .products .all-products .single-product .left .white-box .main-image {
        padding-bottom: 10px;
    }
    .products .all-products .single-product .bottom-row .background-box {
        margin-top: -155%;
        height: 465px;
    }



    .members .title {
        font-size: 18px;
    }
    .members .member-image {
        height: 230px;
    }
    .members .all-members .member-row {
        margin-bottom: 30px;
    }



    .awards .all-awards .single-award {
        padding: 15px 0px;
    }
    .awards .title {
        font-size: 18px;
    }
    .awards .all-awards .single-award .image-div {
        height: 230px;
    }
    .awards .all-awards .single-award .image-div .award-image {
        height: 200px;
        object-fit: cover;
    }



    .testimonials .title {
        font-size: 17px;
    }
    .testimonials .reviews .description {
        font-size: 14px;
        padding-bottom: 15px;
    }
    .testimonials .single-review {
        height: 300px;
        padding: 0 15px;
    }
    .testimonials .reviews .name {
        font-size: 13px;
    }
    


    .blogs .articles .title {
        font-size: 20px;
    }
    .single-blog .title {
        font-size: 18px;
    }
    .single-blog .image {
        height: 190px;
    }
    .single-blog .description {
        line-height: 20px;
    }



    .contact-us .connected .title {
        font-size: 20px;
        padding-bottom: 10px;
    }
    .contact-us .connected .form .form-title {
        font-size: 15px;
        padding-bottom: 10px;
    }
}
/* Responsive */