/*
  Development: Zajjith Vedha
*/


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


/* Common styles */
body {
  font-family: 'Open Sans', sans-serif;
  top: 0px!important;
  position: relative!important;
}
:root {
  --black: #000000;
  --white: #ffffff;
  --brown: #3C3434;
  --green: #2E572B;
  --medium_green: #096049;
  --dark_green: #0E4738;
}
h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
.wrapper {
  max-width: 90%;
  margin: auto;
}
.min-wrapper {
  max-width: 80%;
  margin: auto;
}
.low-wrapper {
  max-width: 65%;
  margin: auto;
}
.lowest-wrapper {
  max-width: 50%;
  margin: auto;
}
.loading {
  font-size: 30px;
  color: var(--brown);
  font-weight: bold;
  padding-top: 35px;
}
/* Common styles */




/* Translate */
#google_translate_element {
  display: flex;
  align-items: center;
}
.goog-te-banner-frame {
  visibility: hidden!important;
}
.goog-te-combo {
  font-family: 'Open Sans', sans-serif!important;
  margin: 0px 0!important;
  width: 125px;
  font-size: 12px;
  padding: 5px;
  border: 1px dashed var(--dark_green);
}
.goog-te-combo:focus-visible {
  outline: 0!important;
}
.skiptranslate > *:last-child {
  display: none;
}
.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
}
/* Translate */




/* Header styles */
.navbar {
  padding: 0;
  height: 80px;
}
.navbar .container-fluid {
  padding: 0 30px;
}
.navbar .navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar .navbar-brand .logo {
  position: absolute;
  top: 0px;
  z-index: 9;
  height: 150px;
  width: 125px;
}
.navbar .nav-item {
  padding: 0 10px;
}
.navbar .nav-item .nav-link {
  font-size: 16px;
  color: var(--brown);
  padding: 10px;
  font-weight: bold;
  border: 3px solid transparent;
  border-radius: 5px;
  transition: all 0.5s;
  background: none;
}
.navbar .nav-item .nav-link.active {
  color: var(--dark_green);
  border: 3px solid var(--dark_green);
}
.navbar .nav-btn .nav-link {
  background-color: var(--green);
  color: var(--white);
  padding: 10px 20px;
  border: 3px solid transparent;
  border-radius: 30px;
}
/* Header styles */


/* Footer styles */
.footer {
  padding-top: 40px;
  position: relative;
}
.footer .footer-banner {
  height: 550px;
}
.footer .min-wrapper {
  margin-top: -285px;
}
.footer .min-wrapper .top {
  margin-bottom: 50px;
}
.footer .footer-logo {
  height: 160px;
  width: 120px;
}
.footer .column1 {
  width: 13%;
  padding-top: 8px;
}
.footer .column2 {
  width: 20%;
}
.footer .column3 {
  width: 16%;
  padding-top: 8px;
}
.footer .column4 {
  width: 15%;
  padding-top: 8px;
}
.footer .column5 {
  width: 36%;
  padding-top: 8px;
}
.footer .footer-image {
  position: absolute;
  top: 25%;
  right: 15.5%;
  z-index: -1;
}
.footer .name {
  font-size: 30px;
  color: var(--white);
  font-weight: bold;
  padding-bottom: 5px;
}
.footer .address, .footer .address a {
  font-size: 14px;
  color: var(--white);
  font-weight: 600;
  padding-bottom: 2px;
}
.footer .title {
  font-size: 18px;
  color: var(--white);
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 10px;
}
.footer ul {
  list-style: none;
  padding-left: 0;
}
.footer ul li {
  padding-bottom: 6px;
}
.footer ul li a {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
}
.footer .description {
  font-size: 13px;
  color: var(--white);
  font-weight: 600;
}
.footer .social {
  padding-top: 30px;
  text-align: center;
}
.footer .social a {
  margin: 0 15px;
}
.footer .bottom .copyright {
  font-size: 14px;
	color: var(--white);
  font-weight: 400;
}
.footer .bottom .developer {
	color: var(--white);
}
.footer .to-top {
	position: absolute;
	right: 4%;
  bottom: 0px;
  color: var(--white);
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
	background-color: crimson;
	border-radius: 5px;
	transition: all 0.5s;
}
.footer .to-top:hover {
	color: var(--white);
	background-color: var(--green);
	transition: all 0.5s;
}
/* Footer styles */


/* Button styles */
/* Button styles */


/* Home styles */
.home {
  padding-top: 15px;
}
.home .banner .background {
  height: 550px;
  width: 100%;
}
/* .home .banner .box {
  justify-content: center;
  align-items: center;
  position: absolute;
}
.home .banner .box .left {
  position: absolute;
  bottom: 62px;
  left: 130px;
}
.home .banner .box .middle {
  position: relative;
  z-index: 1;
  top: 45px;
}
.home .banner .box .right {
  position: absolute;
  right: 18%;
  bottom: 17px;
}
.home .banner .title {
  position: absolute;
  top: 95px;
  font-size: 120px;
  z-index: 11;
  font-weight: bold;
  color: white;
  opacity: 35%;
  text-shadow: 1px 10px 13px rgb(0 0 0 / 60%);
} */
.home .banner .box {
  justify-content: center;
  align-items: center;
  margin-top: -25%;
}
.home .banner .box img {
  width: 100%;
  height: 400px;
}
.home .welcome {
  /* padding-top: 90px; */
  padding-top: 70px;
  padding-bottom: 70px;
}
.home .welcome .title {
  font-size: 40px;
  color: rgb(0, 0, 0, 0.8);
  font-weight: 600;
  padding-bottom: 20px;
}
.home .welcome .title .famco {
  color: rgba(255, 255, 255, 0.8);
  background-color: #074333;
  letter-spacing: 6px;
  padding: 0 8px 0 15px;
  border-radius: 5px;
}
.home .welcome .description {
  font-size: 25px;
  color: rgb(0, 0, 0, 0.50);
  font-weight: 600;
  padding-bottom: 10px;
}
.home .welcome .paragraph {
  font-size: 20px;
  color: rgb(0, 0, 0, 0.35);
  font-weight: 600;
}
.home .vision {
  padding-bottom: 70px;
} 
.home .vision .left {
  position: relative;
  padding-top: 55px;
} 
.home .vision .title {
  font-size: 40px;
  color: var(--green);
  font-weight: bold;
  padding-bottom: 10px;
}
.home .vision .description {
  font-size: 19px;
  color: rgb(0, 0, 0, 0.80);
  font-weight: 600;
  z-index: 1;
  position: relative;
}
.home .vision .right {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  position: relative;
}
.home .vision .back-lines {
  width: 420px;
  height: 400px;
}
.home .vision .vision-image {
  width: 410px;
  height: 310px;
  position: absolute;
  top: 12%;
  border-top-left-radius: 155px;
  border-bottom-right-radius: 155px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  object-fit: cover;
}
.home .vision .leaf {
  position: absolute;
  top: 15%;
  left: 30%;
  height: 250px;
}
.home .mission {
  padding-bottom: 90px;
}
.home .mission .title {
  font-size: 40px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 25px;
  text-align: center;
}
.home .mission .single {
  font-size: 20px;
  color: var(--white);
  font-weight: bold;
  padding: 0 25px;
  background-color: var(--medium_green);
  border-radius: 10px;
  /* height: 260px; */
  height: 200px;
  /* width: 32%; */
  width: 31%;
  margin: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
/* .home .mission .single .sub-title {
  padding-bottom: 25px;
}
.home .mission .single .read-more {
  color: var(--white);
  text-decoration: underline;
} */
.home .home-about-us {
  position: relative;
  padding-bottom: 100px;
}
.home .home-about-us .top {
  position: absolute;
  top: -8%;
}
.home .home-about-us .gray-box {
  width: 30%;
  background-color: rgb(66, 66, 66, 0.2);
  height: 350px;
  border-top-right-radius: 75px;
}
.home .home-about-us .wrapper {
  position: relative;
}
.home .home-about-us .right {
  text-align: center;
  position: relative;
  margin-top: -1%;
}
.home .home-about-us .title {
  font-size: 40px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 20px;
}
.home .home-about-us .title.mobile {
  display: none;
}
.home .home-about-us .description {
  font-size: 19px;
  color: rgb(0, 0, 0, 0.80);
  font-weight: 600;
  padding-bottom: 40px;
}
.home .home-about-us .click-here {
  font-size: 18px;
  color: var(--white);
  font-weight: 600;
  background-color: var(--dark_green);
  border-radius: 5px;
  margin: auto;
  padding: 10px 25px;
  transition: all 0.5s;
}
.home .home-about-us .click-here:hover {
  background-color: var(--green);
  transition: all 0.5s;
}
.home .home-about-us .left {
  position: relative;
}
.home .home-about-us .w-icon-top {
  position: absolute;
  top: -18%;
}
.home .home-about-us .w-icon-bottom {
  position: absolute;
  top: -10%;
  left: 8%;
}
.home .home-about-us .about-image {
  width: 80%;
  height: 350px;
  border-bottom-right-radius: 75px;
  border-top-right-radius: 75px;
  object-fit: cover;
  -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
  box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.75);
}
.home .home-about-us .leaf {
  position: absolute;
  top: -7%;
  left: 20%;
  z-index: -1;
  height: 400px;
}
.home .home-products {
  padding-bottom: 90px;
}
.home .home-products .title {
  font-size: 40px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 25px;
  text-align: center;
}
.home .home-products .product-link {
  color: var(--black);
}
.home .home-products .home-single-product {
  margin-bottom: 80px;
}
.home .home-products .home-single-product.mobile{
  display: none;
}
.home .home-products .home-single-product .image {
  height: 300px;
  width: 250px;
}
.home .home-products .home-single-product .content {
  background-color: rgb(9, 96, 73, 0.15);
  padding: 70px;
  border-radius: 60px;
}
.home .home-products .home-single-product .content .content-title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 5px;
  text-decoration: underline;
}
.home .home-products .home-single-product .content .attributes {
  font-size: 17px;
  font-weight: 600;
}
.home .home-products .home-single-product .content .attributes span {
  font-weight: 700;
}
.home .home-products .home-single-product .image-div {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home .home-products .home-single-product .name {
  color: var(--white);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.home .home-products .home-single-product.first .name {
  background-color: #CC6E0B;
  bottom: -10%;
  right: 25%;
}
.home .home-products .home-single-product.second .name {
  background-color: #61B7DC;
  bottom: -10%;
  left: 18%;
}
.home .home-products .more-products {
  background-color: var(--dark_green);
  color: var(--white);
  border-radius: 5px;
  padding: 10px 25px;
  margin: auto;
  display: table;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
}
.home .home-products .more-products:hover {
  background-color: var(--green);
  transition: all 0.5s;
}
.home .home-awards {
  padding-bottom: 35px;
}
.home .home-awards .title {
  font-size: 40px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 25px;
  text-align: center;
}
.home .home-awards .swiper-wrapper {
  padding-bottom: 120px;
}
.home .home-awards .single-award .single {
  background-color: rgb(9, 96, 73, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
}
.home .home-awards .single-award .single .award {
  height: 180px;
  width: 180px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home .home-awards .single-award .single .description {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.home .home-awards .swiper-button-prev {
  left: 46%;
}
.home .home-awards .swiper-button-next {
  right: 46%;
}
.home .home-awards .swiper-button-next, .home .home-awards .swiper-button-prev {
  top: 90%;
}
.home .home-awards .swiper-button-prev, .home .home-awards .swiper-button-next {
  background-color: var(--dark_green);
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.home .home-awards .swiper-button-prev:hover, .home .home-awards .swiper-button-next:hover {
  background-color: var(--green);
  transition: all 0.5s;
}
.home .home-awards .swiper-button-next:after, .home .home-awards .swiper-button-prev:after {
  font-size: 20px;
  font-weight: 700;
  color: white;
}
.home .home-testimonials {
  padding-bottom: 100px;
}
.home .home-testimonials .title {
  font-size: 40px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 25px;
  text-align: center;
}
.home .home-testimonials .swiper-wrapper {
  padding-bottom: 55px;
}
.home .home-testimonials .single-testimonial .single {
  background-color: var(--dark_green);
  border-radius: 15px;
  display: flex;
  align-items: center;
  height: 350px;
  flex-direction: column;
  padding: 20px 40px;
}
.home .home-testimonials .single-testimonial .single .image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .home-testimonials .single-testimonial .single .testimonial-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  bottom: 0;
  position: absolute;
  left: 11.5%;
}
.home .home-testimonials .single-testimonial .single .quote {
  position: relative;
  display: flex;
  flex-direction: column;
}
.home .home-testimonials .single-testimonial .single .quote .description {
  font-size: 17px;
  color: var(--white);
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.home .home-testimonials .single-testimonial .single .quote .name {
  font-size: 16px;
  color: var(--white);
  font-weight: 500;
  text-align: center;
}
.home .home-testimonials .single-testimonial .single .quote .quote-icon {
  height: 25px;
  width: 25px;
}
.home .home-testimonials .single-testimonial .single .quote .quote-icon.bottom {
  position: absolute;
  right: 0;
  bottom: 20px;
}
.home .home-testimonials .swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: #487A3B;
  height: 13px;
  width: 13px;
}
.home .home-testimonials .swiper-pagination-bullet-active {
  width: 50px!important;
  border-radius: 10px!important;
}
.home .articles .single-article .left {
  width: 49%;
  padding: 0;
  padding-left: 30px;
  padding-right: 3px;
}
.home .articles .single-article .left .article-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.home .articles .single-article .right {
  width: 49%;
  text-align: center;
  padding: 0;
}
.home .articles .single-article .right .title {
  font-size: 35px;
  color: var(--dark_green);
  font-weight: 700;
  padding-bottom: 5px;
}
.home .articles .single-article .right .description {
  font-size: 17px;
  color: var(--black);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  line-clamp: 9;
  -webkit-box-orient: vertical;
}
/* .home .articles .swiper-slide {
  padding-bottom: 150px;
}
.home .articles .single-article .article-line {
  position: absolute;
  width: 92%;
  left: 7%;
  top: 15%;
  z-index: -1;
} */
.home .articles .swiper-button-prev {
  left: 0px;
  background-color: var(--dark_green);
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.home .articles .swiper-button-prev:hover, .home .articles .swiper-button-next:hover {
  background-color: var(--green);
  transition: all 0.5s;
}
.home .articles .swiper-button-next {
  right: 50%;
  background-color: var(--dark_green);
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
.home .articles .swiper-button-next:after, .home .articles .swiper-button-prev:after {
  font-size: 20px;
  font-weight: 700;
  color: white;
}
/* Home styles */


/* About styles */
.about-us {
  margin-top: 60px;
}
.about-us .title {
  font-size: 30px;
  color: var(--dark_green);
  font-weight: bold;
  padding-bottom: 40px;
}
.about-us .main-about .title {
  padding-bottom: 0px;
}
.about-us .description {
  font-size: 16px;
  color: var(--brown);
  font-weight: bold;
}
.about-us .main-about {
  padding-bottom: 70px;
}
.about-us .main-about .title {
  font-size: 30px;
  color: var(--dark_green);
  font-weight: bold;
}
.about-us .main-about .background {
  width: 90%;
  margin-left: auto;
  padding: 20px;
  border-radius: 25px;
  background-image:linear-gradient(to bottom right, rgb(180 255 166), rgb(204 255 0 / 100%));
}
.about-us .main-about .about-image {
  height: 250px;
  width: 250px;
  object-fit: cover;
}
.about-us .brands {
  padding-bottom: 90px;
}
.about-us .brands .brand-image {
  height: 125px;
  margin: 0 20px;
  object-fit: cover;
}
.about-us .supply {
  padding-bottom: 70px;
}
.about-us .supply .map {
  height: 400px;
  margin-bottom: 20px;
}
.about-us .company {
  padding-bottom: 70px;
}
.about-us .company .video-div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 30px;
}
.about-us .company .pos-abs {
  position: absolute;
}
.about-us .company .about-us .company .elements {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} .elements {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.about-us .company .video-image {
  width: 600px;
  height: 350px;
  object-fit: cover;
  position: absolute;
}
.about-us .company iframe {
  width: 600px;
  height: 350px;
}
.about-us .company .blur {
  background-color: rgb(39, 248, 248, 0.4);
  width: 600px;
  height: 350px;
  position: absolute;
}
.about-us .company .play {
  position: absolute;
  width: 65px;
  height: 65px;
}
/* .about-us .management {
  padding-bottom: 80px;
} */
.about-us .management .top-row {
 margin-bottom: 60px;
}
.about-us .management .founder-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}
.about-us .management .name {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
}
.about-us .management .designation {
  font-size: 13px;
  color: var(--black);
  font-weight: 600;
}

.about-us .management .bottom-row .top-column {
  /* margin-bottom: 80px; */
  margin-bottom: 20px;
}
.about-us .management .bottom-row .top-column .member-row {
  margin-bottom: 40px;
}
/* .about-us .management .manager-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
} */
.about-us .management .member-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}
.about-us .management .top-column .description {
  margin-top: -7%;
}
.about-us .management .award-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 10px;
}

.about-us .members .description {
  margin-bottom: 30px;
}
.about-us .members .top-row {
  margin-bottom: 10px;
}
.about-us .members .management-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}
.about-us .members .name {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
}
.about-us .members .designation {
  font-size: 13px;
  color: var(--black);
  font-weight: 600;
}
.about-us .members .bottom-row {
  justify-content: space-between;
}
.about-us .members .bottom-row .column {
  width: 33%;
  text-align: center;
  margin-top: 40px;
}
/* About styles */



/* Contact styles */
.contact-us {
  margin-top: 50px;
}
.contact-us .connected {
  padding-bottom: 10px;
}
.contact-us .connected .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  text-align: center;
  padding-bottom: 35px;
}
.contact-us .connected .title span {
  color: var(--dark_green);
}
.contact-us .connected .form {
  background-image: linear-gradient(to bottom right, #166C56, rgb(195, 234, 38, 0.9));
  padding: 40px;
  border-radius: 30px;
}
.contact-us .connected .form .form-title {
  font-size: 25px;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  padding-bottom: 35px;
}
.contact-us .connected .form .fields {
  padding: 0 120px;
}

.contact-us .connected .form .form-input {
  position: relative;
  margin-bottom: 10px;
}
.contact-us .connected .form .form-label {
  color: rgb(14, 71, 56, 0.5);
  font-size: 12px;
  position: absolute;
  top: 5px;
  left: 30px;
}
.contact-us .connected .form .form-control {
  height: 55px;
  width: 100%;
  outline: 0;
  border: 0;
  border-radius: 7px;
  color: var(--dark_green);
  font-size: 15px;
  font-weight: 600;
  box-shadow: none;
  padding: 15px 0 0 18px;
}
.contact-us .connected .form .form-control:focus {
  border: 2px solid var(--dark_green);
}
.contact-us .connected .form textarea {
  height: 200px!important;
  padding: 25px 18px 18px 18px!important;
}
.contact-us .connected .form .send-btn {
  height: 50px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 20px;
  border: 4px solid var(--white);
  border-radius: 15px;
  background-color: #1877F2;
  margin: auto;
  transition: all 0.5s;
}
.contact-us .connected .form .sending-btn {
  height: 60px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 20px;
  border: 4px solid var(--white);
  border-radius: 15px;
  background-color: #1877F2;
  margin: auto;
  transition: all 0.5s;
}
.contact-us .connected .form .send-btn i, .contact-us .connected .form .sending-btn i {
  padding-right: 5px;
}
.contact-us .connected .form .send-btn:hover {
  background-color: #0e55b1;
  transition: all 0.5s;
}

.contact-us .contact .information {
  background-image: linear-gradient(to bottom right, #166C56, rgb(195, 234, 38, 0.9));
  padding: 40px;
  border-radius: 30px;
  margin-top: 40px;
}
.contact-us .contact .information .title {
  font-size: 25px;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  padding-bottom: 35px;
}
.contact-us .contact .information iframe {
  border: none;
  border-radius: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 275px;
}
.contact-us .contact .information .location-title {
  font-size: 17px;
  color: var(--dark_green);
  font-weight: bold;
  text-align: center;
  background-color: var(--white);
  display: table;
  padding: 15px 40px;
  margin: auto;
  box-shadow: inset 1px 0px 1px 0px rgb(0 0 0 / 40%);
  border-radius: 10px;
  position: relative;
  top: -15px;
}
.contact-us .contact .information .address {
  font-size: 16px;
  color: var(--white);
  font-weight: bold;
  padding-top: 0px;
}
.contact-us .contact .information .contact-icon {
  padding-bottom: 15px;
}
.contact-us .contact .information .contact-icon a {
  font-size: 16px;
  color: var(--white);
  font-weight: 700;
}
.contact-us .contact .information .contact-icon a img {
  border: 1px solid var(--white);
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
  height: 30px;
}
.contact-us .contact .information .social {
  background-color: var(--white);
  display: table;
  padding: 15px 25px;
  margin: auto;
  -webkit-box-shadow: inset 1px 0px 1px 0px rgb(0 0 0 / 40%);
  -moz-box-shadow: inset 1px 0px 1px 0px rgb(0 0 0 / 40%);
  box-shadow: inset 1px 0px 1px 0px rgb(0 0 0 / 40%);
  border-radius: 10px;
  margin-top: 80px;
}
.contact-us .contact .information .social a {
  margin: 0 15px;
}
.contact-us .success-modal .modal-content {
  border-radius: 0;
}
.contact-us .success-modal .message-sent {
  font-size: 40px;
  color: var(--green);
  text-align: center;
  font-weight: 600;
  font-style: italic;
}
.contact-us .success-modal .modal-footer {
  padding: 5px 15px;
}
/* Contact styles */


/* Blogs styles */
.blogs .search {
  padding-top: 50px;
}
.blogs .search .search-bar {
  position: relative;
}
.blogs .search .form-control {
  height: 60px;
  border-radius: 50px;
  font-size: 18px;
  letter-spacing: 2px;
  color: var(--green);
  -webkit-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.20);
  box-shadow: 0px 3px 7px 1px rgba(0,0,0,0.20);
}
.blogs .search .form-control::placeholder {
  letter-spacing: 4px;
  font-size: 16px;
}
.blogs .search .form-control:focus {
  border-color: #ced4da!important;
  outline: 0;
  /* box-shadow: none!important; */
}
.blogs .search .search-icon {
  position: absolute;
  right: 18px;
  top: 5px;
  border: 0;
  background-color: #096041;
  color: var(--white);
  font-size: 20px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogs .articles {
  padding-top: 35px;
}
.blogs .articles .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 30px;
  text-align: center;
}
.blogs .articles .title span {
  color: var(--dark_green);
  text-decoration: underline;
}
.blogs .articles .nav-pills {
  justify-content: center;
  padding-bottom: 35px;
}
.blogs .articles .nav-pills .nav-item {
  margin: 0 10px;
}
.blogs .articles .nav-pills .nav-item .nav-link {
  font-size: 16px;
  color: #1E1E1E;
  font-weight: bold;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
}
.blogs .articles .nav-pills .nav-item .nav-link.active {
  color: var(--white);
  background-color: var(--medium_green);
  border-radius: 10px;
}
.blogs .articles .top {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.blogs .articles .top .left {
  width: 48%;
}
.blogs .articles .top .right {
  width: 48%;
}
.blogs .articles .top .blog-main-image {
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
}
.blogs .articles .top .blog-main-title {
  font-size: 19px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 10px;
  text-align: center;
}
.blogs .articles .top .blog-main-description {
  font-size: 16px;
  color: var(--brown);
  font-weight: 500;
  text-align: center;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
}
.blogs .articles .top .author {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.blogs .articles .top .author-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.blogs .articles .top .author .details .name {
  font-size: 16px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 0px;
}
.blogs .articles .top .author .details .date {
  font-size: 11px;
  color: var(--brown);
  font-weight: 600;
}
.blogs .articles .bottom {
  justify-content: space-between;
}
.blogs .articles .bottom .single-article {
  width: 31%;
  margin-top: 40px;
}
.blogs .articles .bottom .blog-sub-image {
  height: 170px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 15px;
}
.blogs .articles .bottom .blog-sub-title {
  font-size: 18px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 5px;
}
.blogs .articles .bottom .blog-sub-description {
  font-size: 15px;
  color: var(--brown);
  font-weight: 500;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blogs .articles .bottom .author {
  display: flex;
  align-items: center;
  padding-top: 15px;
}
.blogs .articles .bottom .author-image {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.blogs .articles .bottom .author .details .name {
  font-size: 14px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 1px;
}
.blogs .articles .bottom .author .details .date {
  font-size: 10px;
  color: var(--brown);
  font-weight: 600;
}
/* Blogs styles */


/* Single blog styles */
.single-blog {
  padding-top: 50px;
}
.single-blog .title {
  font-size: 35px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 20px;
  text-align: center;
}
.single-blog .image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 20px;
}
.single-blog .description {
  font-size: 16px;
  color: var(--brown);
  font-weight: 500;
  line-height: 28px;
}
/* Single blog styles */


/* Testimonials styles */
.testimonials {
  padding-top: 50px;
}
.testimonials .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  text-align: center;
}
.testimonials .title span {
  color: var(--dark_green);
  text-decoration: underline;
}
.testimonials .reviews {
  justify-content: space-between;
  margin-top: 50px;
}
.testimonials .single-review {
  width: 31%;
  margin-bottom: 75px;
  text-align: center;
  border: 1px solid var(--black);
  position: relative;
  height: 475px;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
}
.testimonials .single-review .shape {
  position: absolute;
  left: -1px;
  top: -4%;
}
.testimonials .single-review .quote {
  position: absolute;
  left: 15px;
  top: 4%;
}
.testimonials .reviews .review-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}
.testimonials .reviews .description {
  font-size: 16px;
  color: var(--brown);
  font-weight: 600;
  padding-bottom: 25px;
  font-style: italic;
}
.testimonials .reviews .name {
  font-size: 16px;
  color: var(--brown);
  font-weight: bold;
  padding-bottom: 3px;
}
.testimonials .reviews .designation {
  font-size: 12px;
  color: var(--brown);
  font-weight: 600;
}
.testimonials .reviews .bars {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: -0.5px;
  bottom: -0.5px;
}
.testimonials .reviews .bar {
  width: 50px;
  height: 8px;
}
.testimonials .reviews .bar1 {
  background-color: var(--dark_green);
}
.testimonials .reviews .bar2 {
  background-color: #C3EA26;
}
.testimonials .swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: var(--dark_green);
  height: 13px;
  width: 13px;
}
/* Testimonials styles */


/* Awards styles */
.awards {
  padding-top: 50px;
}
.awards .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  text-align: center;
}
.awards .title span {
  color: var(--dark_green);
  text-decoration: underline;
}
.awards .all-awards .single-award {
  width: 100%;
  background-image: linear-gradient(to bottom right, #166C56, rgb(195, 234, 38, 0.9));
  border-radius: 30px;
  text-align: center;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  padding: 40px 25px;
  -webkit-box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
  -moz-box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
  box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
}
.awards .all-awards .single-award .award-title {
  font-size: 24px;
  color: var(--white);
  font-weight: bold;
  padding-bottom: 5px;
}
.awards .all-awards .single-award .image-div {
  background-color: var(--white);
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 15px;
}
.awards .all-awards .single-award .image-div .award-image {
  width: 100%;
  height: 320px;
  object-fit: fill;
}
.awards .all-awards .single-award .award-description {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
  font-style: italic;
}
/* Awards styles */


/* Managements styles */
.members {
  padding-top: 50px;
}
.members .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  text-align: center;
}
.members .all-members {
  margin-top: 50px;
}
.members .all-members .member-row {
  margin-bottom: 40px;
}
.members .member-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 10px;
}
.members .all-members .name {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
}
.members .all-members .designation {
  font-size: 13px;
  color: var(--black);
  font-weight: 600;
}
.members .description {
  font-size: 16px;
  color: var(--brown);
  font-weight: bold;
  margin-top: -7%;
}
/* Managements styles */


/* Products styles */
.products {
  padding-top: 50px;
}
.products .title {
  font-size: 40px;
  color: var(--brown);
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}
.products .title span {
  color: var(--dark_green);
  text-decoration: underline;
}
.products .description {
  font-size: 17px;
  color: var(--brown);
  font-weight: 600;
  text-align: center;
  width: 80%;
  margin: auto;
}
.products .all-products .single-product {
  margin-top: 60px;
}
.products .all-products .single-product .top-row {
  background-image: linear-gradient(to bottom right, #166C56, rgb(195, 234, 38, 0.9));
  /* align-items: center; */
  padding: 25px 30px 60px 30px;
}
.products .all-products .single-product .left {
  width: 48%;
  padding-top: 4.5%;
}
.products .all-products .single-product .right {
  width: 48%;
}
.products .all-products .single-product .left .white-box {
  background-color: var(--white);
}
.products .all-products .single-product .left .white-box .main-image {
  background: rgb(13,143,47);
  background: linear-gradient(180deg, rgba(13,143,47,1) 0%, rgba(10,83,21,1) 73%);
  text-align: center;
  padding: 35px;
}
.products .all-products .single-product .left .white-box .main-image .featured-image {
  width: 220px;
  height: 190px;
  object-fit: cover;
}
.products .all-products .single-product .left .white-box .other-images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.products .all-products .single-product .left .white-box .other-images .other-image {
  width: 120px;
  height: 100px;
  margin: 0px 10px;
  padding: 10px;
  object-fit: contain;
  border: 1.5px solid var(--dark_green);
  cursor: pointer;
}
.products .all-products .single-product .right .product-title {
  font-size: 30px;
  color: var(--white);
  font-weight: bold;
  padding-bottom: 5px;
}
.products .all-products .single-product .right .product-description {
  font-size: 16px;
  color: var(--white);
  font-weight: 600;
}

.products .all-products .single-product .bottom-row {
  margin-top: -2%;
  position: relative;
}
.products .all-products .single-product .bottom-row .sub-images {
  padding: 0 15px;
}
.products .all-products .single-product .bottom-row .sub-images .image-column {
  width: 20%;
  position: relative;
}
.products .all-products .single-product .bottom-row .sub-images .image-column img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border: 7px solid var(--white);
  -webkit-box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
  -moz-box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
  box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 60%);
}
.products .all-products .single-product .bottom-row .sub-images .image-column .image-description {
  font-size: 14px;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  padding-top: 25px;
}
.products .all-products .single-product .bottom-row .background-box {
  background-image: linear-gradient(to bottom right, #166C56, rgb(195, 234, 38, 0.9));
  height: 170px;
  margin-top: -12%;
}
.products .all-products .single-product .bottom-row .sub-images .image-column .bar {
  width: 5px;
  height: 75px;
  background-color: var(--white);
  position: absolute;
  right: -2px;
  bottom: -10px;
  border-radius: 5px;
}
.products .all-products .single-product .bottom-row .sub-images .image-column .image-name-div {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.products .all-products .single-product .bottom-row .sub-images .image-column .image-name-div .image-name {
  font-size: 15px;
  color: var(--white);
  background-image: linear-gradient(to bottom right, #5e5e5ec7, rgb(126 113 113 / 69%));
  display: inline-flex;
  padding: 7px 15px;
  border-radius: 20px;
  position: absolute;
  bottom: 10%;
  letter-spacing: 1.2px;
}
.products .all-products .single-product .bottom-row .sub-images .image-column:last-child .bar {
  display: none;
}
/* Products styles */